import { createUseStyles } from 'react-jss'


export default createUseStyles({
  container: {
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
})
