import { createUseStyles } from 'react-jss'

import { colors, tablet, desktop, maxDesktop } from '../../theme'


export default createUseStyles({
  container: {
    height: '100vh',
    width: '100%',
    paddingTop: '4.6rem',
    overflow: 'hidden auto',
  },
  wrapper: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(16rem, auto))',
    marginLeft: '-1px',
    marginRight: '-1px',
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      height: '2px',
      zIndex: 3,
      background: colors.pureBlack,
      pointerEvents: 'none',
    },
    '&::after': {
      bottom: '-1px',
    },
    '&::before': {
      top: 0,
    },
    ...tablet({
      gridTemplateColumns: 'repeat(3, minmax(18rem, auto))',
    }),
    ...desktop({
      gridTemplateColumns: 'repeat(6, minmax(18rem, auto))',
    }),
  },
  look: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    borderTop: `1px solid ${colors.pureWhite}`,
    borderbottom: `1px solid ${colors.pureWhite}`,
    borderLeft: `1px solid ${colors.pureWhite}`,
    borderBottom: 0,
    maxHeight: '35rem',
    ...tablet({
      maxHeight: '32rem',
    }),
    ...desktop({
      maxHeight: '32rem',
    }),
    ...maxDesktop({
      maxHeight: '55rem',
    }),
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      pointerEvents: 'none',
    },
    '&::after': {
      top: '-1px',
      bottom: '-1px',
      left: '100%',
      width: '1px',
      background: colors.pureWhite,
    },
    '&::before': {
      top: '100%',
      left: 0,
      right: 0,
      height: '1px',
      background: colors.pureWhite,
    },
    '&:last-child': {
    },
  },
  image: {
    width: '100%',
    height: '100%',
    '&:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
  },
})
