import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import withMemo from '../../decorators/withMemo'
import LookGrid from '../../components/LookGrid'

import useStyles from './styles'


const LooksTemplate = (props) => {
  const classes = useStyles(props)
  const { className, looks } = props


  // animations
  const $animatedWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedWrapper.current) {
      const animatedChildren = Array
        .from($animatedWrapper.current.children)
        .filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedChildren, timeline, 1, '+=0.2', 0.5)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [classes.image])

  return (
    <main
      className={cx(className, classes.container)}
      ref={$animatedWrapper}
    >
      <div className={classes.wrapper}>
        {looks && <LookGrid looks={looks} />}
      </div>
    </main>
  )
}

export const LooksTemplatePropTypes = {
  className: PropTypes.string,
  looks: PropTypes.any,
}

LooksTemplate.propTypes = LooksTemplatePropTypes

LooksTemplate.defaultProps = {
  className: null,
  looks: null,
}

export default withMemo()(LooksTemplate)
