import React, { useCallback, useMemo } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import { navigate } from 'gatsby-plugin-intl'

import { useIntl } from '../../intl/intl'
import { withMemo, withWebsiteOpen } from '../../decorators'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Icon from '../../components/Icon'
import { iconsKeys } from '../../components/Icon/Icon.assets'
import LooksTemplate from '../../templates/Looks'
import router from '../../router'
import routes from '../../router/routes'
import * as appConfiguration from '../../configuration'



const Looks = (props) => {
  const { pageContext, data, location } = props
  const t = useIntl()

  console.log(pageContext?.isVip)

  const onClose = useCallback(() => {
    navigate(router(pageContext?.isVip ? routes.vr.path : routes.home.path))
  }, [pageContext.isVip])

  const looksTemplateProps = useMemo(() => ({
    layoutProps: {
      seo: {
        lang: pageContext?.language ?? null,
        title: data?.contentfulLooks?.seo?.title ?? '',
        description: data?.contentfulLooks?.seo?.description?.description ?? '',
        image: appConfiguration.app.url + data?.contentfulLooks?.seo?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
        url: location?.href ?? '',
      },
    },
    headerProps: {
      isFallHidden: true,
      linkLogoProps: {
        link: t('balenciaga_logo_link'),
        target: '_blank',
      },
    },
    looksProps: {
      looks: data?.contentfulLooks?.content?.map((look) => ({
        id: look?.id ?? null,
        fluid: look?.picture?.localFile?.childImageSharp?.fluid ?? null,
        route: router(pageContext?.isVip ? routes.vipGridPreset.path : routes.gridPreset.path, { slug: look.slug }),
        embedUrl: look?.modelUrl ?? '',
        title: look?.title ?? '',
      })),
    },
  }), [t, pageContext.isVip, pageContext.language, data.contentfulLooks.seo.title, data.contentfulLooks.seo.description.description, data.contentfulLooks.seo.picture.localFile.childImageSharp.fluid.src, data.contentfulLooks.content, location.href])

  return (
    <Layout
      header={(
        <Header
          navigation={(
            <>
              <Icon
                icon={iconsKeys.Infos}
                onClick={() => {
                  navigate(router(pageContext?.isVip ? routes.vipPage.path : routes.page.path, { slug: 'press' }))
                }}
              />
              <Icon
                icon={iconsKeys.ToCarousel}
                onClick={() => {
                  navigate(router(pageContext?.isVip ? routes.vipGridDefault.path : routes.gridDefault.path))
                }}
              />
              <Icon
                icon={iconsKeys.Close}
                onClick={onClose}
              />
            </>
          )}
          {...looksTemplateProps.headerProps}
        />
      )}
      {...looksTemplateProps.layoutProps}
    >
      <LooksTemplate {...looksTemplateProps.looksProps} />
    </Layout>
  )
}

Looks.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}
Looks.defaultProps = {
  data: null,
  pageContext: null,
  location: null,
}

export default withWebsiteOpen(withMemo()(Looks))

export const query = graphql`
  query looks($language: String){
    contentfulLooks(node_locale: { eq: $language }) {
      ...balenciagaF21Looks
    }
  }
`
