import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import withMemo from '../../decorators/withMemo'
import Image from '../Image'
import Link from '../Link'

import useStyles from './styles'


const LookGrid = (props) => {
  const classes = useStyles(props)
  const { className, looks } = props

  // animations
  const $animatedWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedWrapper.current) {
      const animatedChildren = Array
        .from($animatedWrapper.current.querySelectorAll('.is-staggered'))
        .filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedChildren, timeline, 1.5, '+=0.001', 0.1)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [classes.image])

  return (
    <div className={cx(className, classes.container)}>
      <div
        className={classes.wrapper}
        ref={$animatedWrapper}
      >
        {looks.map((look) => (
          <Link
            route={look.route}
            className={cx(classes.look)}
            key={look.id}
          >
            {look.fluid && (
              <Image
                img={look.fluid}
                className={cx(classes.image, 'is-staggered')}
              />
            )}
          </Link>
        ))}
      </div>
    </div>
  )
}

LookGrid.propTypes = {
  className: PropTypes.string,
  looks: PropTypes.any,
}

LookGrid.defaultProps = {
  className: null,
  looks: PropTypes.any,
}

export default withMemo()(LookGrid)
